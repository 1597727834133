@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Outfit:wght@100..900&display=swap');

* {
  margin: 0;
  padding: 0;
}
/* navbar */
/* Default styles for larger screens */
.navbar {
  background-color: #fff; /* Add your background color */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for aesthetics */
  padding: 10px 15px;
}

.navbar-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 120px; 
}
.contact-number {
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.navbar-toggler {
  border: none;
  background: none;
  outline: none;
}

.navbar-toggler:focus {
  outline: none;
}

.collapse.navbar-collapse {
  flex-grow: 1;
}

.navbar-nav {
  display: flex;
  justify-content: center;
  width: 100%;
}

.nav-item {
  margin: 0 10px;
}

.nav-link {
  color: #333;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px 10px;
}

.nav-link.active {
  color: #007bff;
}

.headicons {
  color: #104990; /* Icon color */
 font-size: 19px;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blink {
  animation: blink 1s infinite;
}

  @media screen and (max-width: 768px) {
    @supports (-webkit-overflow-scrolling: touch) and (pointer: coarse) {
        .navbar {
            padding: 5px;
        }

        .navbar-content {
            flex-direction: row;
            justify-content: space-between;
        }

        .icon-links {
            flex-grow: 1;
            justify-content: center;
        }

        .contact-number {
            font-size: 1rem; 
            margin: 0 5px;
        }
     
       
    }
}
.inputsearch {
  border-top: 1px solid #104990;
  border-left: 1px solid #104990;
  border-bottom: 1px solid #104990;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.inputsearch:hover {
  border-top: 1px solid #104990;
  border-left: 1px solid #104990;
  border-bottom: 1px solid #104990;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.btnsearch {
  font-size: 17px;
  background: #104990;
  color: #fff;
  padding: 4.8px 0px 4.8px 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: -3px;
}
.contactmap{
  width: 100%;
}
@media (max-width: 992px) {
  .navbar-nav.m-auto {
    margin: 0 auto;
  }
  .form-inline {
    width: 100%;
    margin-top: 1rem;
  }
  .inputsearch,
  .btnsearch {
    width: 100%;
    border-radius: 4px;
  }
  .icon-circle {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}
@media (max-width: 768px) {
  .logo {
    width: 100px;
  }
}
@media (max-width: 576px) {
  .navbar-nav.m-auto {
    margin: 0 auto;
    text-align: center;
  }

  .form-inline {
    width: 70%;
    margin-top: 2rem;
  }
  .inputsearch,
  .btnsearch {
    width: 20%;
    border-radius: 4px;
  }
  .icon-circle {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}

.marqueebg {
  background: linear-gradient(rgba(249, 250, 252, 0.9), rgba(217, 236, 255, 0.9)) !important;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blinking-text {
  animation: blink 1s infinite;
  color: #f6021a;
}

.marqueetxt {
  font-size: larger;
  font-weight: 700;
  color: #000;
}

/* slider */
.carousel-image {
  height: 530px;
}

.besfrerow {
  background: #104990;
  color: white;
}

.bestfeicon {
  color: white;
  font-size: 80px;
}

.bestfeicon:hover {
  color: #96c814;
}

.servicebtn {
  background: #104990 !important;
  border-radius: 35px !important;
  padding: 8px 20px !important;
  color: #fff !important;
  font-weight: 400 !important;
}

.servicebtn:hover {
  background: #96c814 !important;
  border-radius: 35px !important;
  padding: 8px 20px !important;
  color: #fff !important;
  font-weight: 400 !important;
}

.service-card {
  margin-top: 0px;
  padding-top: 0px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.service-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #104990;
  color: #ffffff !important;
  margin-right: 15px;
}

.icon-circle:hover {
  background-color: #ffffff !important;
  color: #104990 !important;
  border: 1px solid #104990;
}

.sbtn {
  width: 120px;
  height: 40px;
  font-size: 18px;
  font-weight: 600;
}

.icon {
  font-size: 50px;
  color: #104990;
  margin-bottom: 15px;
}
/* services */
.servicecarbg {
  background: linear-gradient(#F9FAFC, rgba(217, 236, 255, 0.95));
  box-shadow: none;
  transition: background 0.3s, box-shadow 0.3s;
}

.servicecarbg:hover {
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.vicons {
  background: #104990;
  text-align: center;
  padding: 15px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  font-size: 32px !important;
  color: #fff;
}

.servicecarbg:hover .vicons {
  background: #96c814;
}

/* about */
.about-bg {
  background: #f5f5f5 !important;
}

.custom-line-height {
  line-height: 1.9;
}

/* product */
.product-card {
  width: 300px; 
  height: 430px; 
  margin: auto;
  overflow: hidden; 
}

.product-image-fixed {
  width: 100%;
  height: 250px; 
  object-fit: cover; 
}
.product .btn {
  margin-top: auto;
  background: #104990 !important;
  border: none;
}

.product .btn:hover {
  margin-top: auto;
  background: #96c814 !important;
  border: none;
}

/* btn view more */
.viewmore {
  background: #104990 !important;
  margin-top: 5px !important;
  border-radius: 35px !important;
  padding: 10px 20px !important;
  color: #fff !important;
  font-weight: 600 !important;
}
.viewmore:hover {
  background: #96c814 !important;
  margin-top: 5px !important;
  border-radius: 35px !important;
  padding: 10px 20px !important;
  color: #fff !important;
  font-weight: 600 !important;
}
.fgmap{
  width: 450px !important;
  height: 250px !important;
}
/* bestfe1 */
.head1 {
  font-size: 70px;
  color: #104990;
}
.head2 {
  font-size: 34px;
}
.best1colbg {
  background: #104990;
  border-radius: 20px;
}
.icon-circle-b {
  text-align: center;
  padding: 20px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  font-size: 44px;
  color: #104990 !important;
}
.custome-line-height {
  line-height: 1.9;
}
.common-head {
  background: linear-gradient(rgba(230, 234, 241, 0.9), rgba(148, 182, 240, 0.9)), url('./assests/images/commonhead.png');
  background-size: cover;
  background-repeat: repeat;
}
.product-head {
  background: linear-gradient(rgba(230, 234, 241, 0.9), rgba(148, 182, 240, 0.9)), url('./assests/images/commonhead.png');
  background-size: cover;
  background-repeat: repeat;
}
.bg-banner {
  background: linear-gradient(rgba(10, 10, 123, 0.9), rgba(72, 72, 247, 0.9)), url('./assests/images/bannerpurity.png');
  background-attachment: fixed;
  background-position: center;
  height: 400px;
  background-size: cover;
}
.bestfe-banner {
  background: linear-gradient(rgba(184, 184, 245, 0.9), rgba(250, 250, 251, 0.9)), url('./assests/images/b3.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
.team-banner {
  background: linear-gradient(rgba(184, 184, 245, 0.9), rgba(250, 250, 251, 0.9)), url('./assests/images/teambanner.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
.teambg-banner {
  background: linear-gradient(rgba(184, 184, 245, 0.9), rgba(250, 250, 251, 0.9));
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
.phonebtn {
  font-size: 24px !important;
}
.bestfe3bg {
  background: #104990;
}
.bestbtn {
  background: #fff !important;
  border-radius: 35px !important;
  padding: 8px 20px !important;
  color: #00325a !important;
  font-weight: 400 !important;
}


.icon-circle-why {
  background: #104990;
  text-align: center;
  padding: 20px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  font-size: 44px;
  color: #fff !important;
}

.icon-circle-why:hover,
.bestfeicon:hover{
  background: #96c814;
  text-align: center;
  padding: 12px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  font-size: 84px !important;
  color: #fff !important;
}

/* vision */
.order-md-1 {
  order: 1;
}

.order-md-2 {
  order: 2;
}

.visioneye {
  font-size: 500px;
  color: #104990;
}

/* mission */
.missionimg {
  width: 250px;
}
.viewbtn {
  background: #104990 !important;
  border-radius: 35px !important;
  padding: 15px 25px !important;
  color: #fff !important;
}
/* team */
h1 div {
  position: relative;
  float: left;
}
.blog .carousel-indicators {
  left: 0;
  top: auto;
  bottom: -40px;
}
.blog .carousel-indicators li {
  background: #a3a3a3;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-bottom: 10px;
}
.blog .carousel-indicators .active {
  background: #707070;
  margin-bottom: 10px;
}
.our-team-section {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
}
.our-team-section:before {
  position: absolute;
  top: -0;
  left: 0;
  content: " ";
  background-size: 100% 100px;
  width: 100%;
  height: 100px;
  float: left;
  z-index: 99;
}
.our-team {
  padding: 0 0 40px;
  background: #f9f9f9;
  text-align: center;
  overflow: hidden;
  position: relative;
  border-bottom: 5px solid #104990;
}
.teampills{
  color: #000 !important;
}
.teampills.active, 
.teampills:focus, 
.teampills:active {
  color: #fff !important;                
  background-color: #104990 !important;              
}
.our-team:hover {
  border-bottom: 5px solid #96c814;
}
.our-team .pic {
  display: inline-block;
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
  z-index: 1;
  position: relative;
}
.our-team .pic:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 1;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}
.our-team:hover .pic:before {
  height: 100%;
}
.our-team .pic:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #ffffff00;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all 0.3s linear 0s;
}
.our-team:hover .pic:after {
  background: #96c814;
}
.our-team .pic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
  box-shadow: 0 0 0 14px #f7f5ec;
  transform: scale(0.7);
  position: relative;
  z-index: 2;
}
.our-team:hover .pic img {
  box-shadow: 0 0 0 14px #f7f5ec;
  transform: scale(0.7);
}
.our-team .team-content {
  margin-bottom: 30px;
}
.our-team .title {
  font-size: 22px;
  font-weight: 700;
  color: #000;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.our-team .post {
  display: block;
  font-size: 15px;
  color: #4e5052;
  text-transform: capitalize;
}
.our-team .social {
  width: 100%;
  padding-top: 10px;
  margin: 0;
  background: #96c814;
  position: absolute;
  bottom: -100px;
  left: 0;
  transition: all 0.5s ease 0s;
}
.our-team:hover .social {
  bottom: 0;
}
.our-team .social li {
  display: inline-block;
}
.our-team .social li a {
  display: block;
  padding-top: 6px;
  font-size: 15px;
  color: #fff;
  transition: all 0.3s ease 0s;
}
.our-team .social li a:hover {
  color: #2f2f2f;
  background: #f7f5ec;
}
@media only screen and (max-width: 990px) {
  .our-team {
    margin-bottom: 10px;
  }
}
.slide-in-left {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.slide-in-right {
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.slide-in-top {
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.slide-in-bottom {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.visible {
  opacity: 1;
  transform: translateX(0) translateY(0);
}
/* footer */
.footerbg {
  background: #104990;
}
.copyright-bg {
  background: #96c814;
  color: white;
  font-weight: 700;
}
/* contact us */
.contactinfo {
  background: #104990;
  height: 620px;
}
.contacticon {
  background: #fff;
  text-align: center;
  padding: 15px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 44px;
  color: #104990 !important;
}
.submitbtn {
  background: #104990 !important;
  padding: 10px 20px !important;
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 700 !important;
}
.submitbtn:hover {
  background: #96c814 !important;
  padding: 10px 20px !important;
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 700 !important;
}
.gmap {
  height: 230px;
  width: 100%;
  border: 0px;
}

/* career */
.careerhead {
  background: #104990 !important;
}
.careerformbg {
  background: linear-gradientlinear-gradient(rgba(230, 234, 241, 0.9), rgba(148, 182, 240, 0.9));
}
/* gallery */
.gallery-col {
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.gallery-item {
  height: 400px;
  margin-bottom: 25px;
}
.gallery-img {
  border-radius: 10px;
  flex: 1;
  height: 400px;
  object-fit: cover;
}
.gallery-item:hover .gallery-img {
  opacity: 0.7;
}
.pagination {
  display: flex;
  justify-content: center;
  margin: 30px 0px;
}
.page-button {
  padding: 10px 15px;
  margin: 0 5px;
  border: none;
  background-color: #104990;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}
.page-button.active {
  background-color: #ffffff;
  color: #104990;
  font-weight: 600;
  border: 2px solid #104990;
}
.page-button:hover:not(.active) {
  background-color: #104990;
}
.page-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
/* all products */
.product {
  background: #fff;
  box-shadow: none;
}
.product:hover {
  border-radius: 40px;
  background: #f5f5f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.pcardbody {
  background: #fff;
  box-shadow: none;
}
.pcardbody:hover {
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.productcard {
  background: #fff;
  height: 400px;
  border-radius: 40px;
}
input {
  border: none;
  outline: none;
}
input:hover,
input:active,
input:focus {
  border: none;
  outline: none;
}
.sinput {
  border: 2px solid #104990;
}
.searchicon {
  color: #104990;
  font-weight: 900;
}
/* filter */
.custom-modal .modal-dialog {
  min-width: 25%;
  height: 100%;
  margin: 0;
  position: fixed;
  top: 0;
  right: 0;
}
.custom-modal .modal-content {
  height: 100vh;
  border-radius: 0;
}
.closeicon {
  color: #104990;
  margin-top: 2px;
  font-weight: 900;
}
.filtericon {
  color: #104990;
}
.allproductheading {
  color: #104990;
  font-weight: 700;
}
.filterbody {
  border: 1px solid #104990;
}
.custom-modal .modal-dialog {
  max-width: 30%;
  height: 100%;
  margin: 0;
  position: fixed;
  top: 0;
  right: 0;
}
.custom-modal .modal-content {
  height: 100vh;
  border-radius: 0;
}
.custom-label {
  color: #000;
}
.custom-hr {
  border-top: 1px solid #104990;
}
.filterbody {
  padding: 20px;
}
.closeicon {
  cursor: pointer;
}
.bordered {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.bapply {
  background: #104990;
  color: #ffffff;
  font-weight: 400;
}
.custom-modal .modal-dialog {
  max-width: 30%;
  height: 100%;
  margin: 0;
  position: fixed;
  top: 0;
  right: 0;
}
.custom-modal .modal-content {
  height: 100vh;
  border-radius: 0;
}
.allproductheading {
  color: #104990;
  font-weight: bold;
}
.custom-label {
  color: #000;
}
.custom-hr {
  border: 1px solid #104990;
}
.filterbody {
  padding: 20px;
}
.closeicon {
  cursor: pointer;
}
.bordered {
  padding: 10px;
  border: 1px solid #104990;
  border-radius: 5px;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #104990;
  border-color: #104990;
}
.bclose {
  background-color: #fff;
  border-color: #104990;
  color: #104990;
}
.bapply {
  background-color: #66cc66;
  border-color: #66cc66;
  color: white;
}
.bclose:hover {
  background-color: #66cc66;
  border-color: #66cc66;
  color: white;
}
.bapply:hover {
  background-color: #fff;
  border-color: #104990;
  color: #104990;
}
/* login */
.loginbtn {
  background: #104990;
  border: 1px solid #104990;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
}
/* registrasion */
.rbtn {
  color: #104990;
  font-size: 16px;
  font-weight: 900;
}
.usericon {
  color: #104990;
}
.form-control:focus {
  border-color: #104990;
}
.form-control.is-invalid {
  border-color: #dc3545;
}
.form-control.is-valid {
  border-color: #104990;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.throwbg {
  background: #104990;
  color: #fff;
  font-size: 20px;
}
/* enqury */
.enquirybtn {
  background: #104990;
  color: #ffffff;
  font-weight: 400;
}
/* checkout */
.cardheader {
  background: #104990;
  color: #fff;
  font-size: 20px;
  padding: 10px;
}
.carouselbtn {
  background: #104990;
  color: #fff;
  border: none;
  padding: 7px 10px;
  border-radius: 5px;
}
/* careerform */
.file {
  background: white;
  border: none;
}
/* productdetails */
.pdetailshr {
  background: #104990;
}
.bottlesize {
  background: #104990;
  color: #fff;
  font-weight: 600;
  border: none;
}
.addtocart {
  background: #104990;
  color: #fff;
  font-weight: 600;
}
.addtocart:hover {
  background: #96c814;
  color: #fff;
  font-weight: 800;
}
.downloadpdf {
  background: #104990;
  color: #fff;
  font-weight: 600;
}
.downloadpdf:hover {
  background: #96c814;
  color: #fff;
  font-weight: 800;
}
.shareicon {
  padding-left: 110px;
  margin-left: 110px;
  color: #104990;
  font-size: 20px;
  font-weight: 600;
}
/* NotFound.css */
.not-found-container {
  text-align: center;
  padding: 50px;
}
.not-found-heading {
  font-size: 72px;
  margin-bottom: 20px;
}
.not-found-subheading {
  font-size: 36px;
  margin-bottom: 20px;
}
.not-found-text {
  font-size: 18px;
  margin-bottom: 30px;
}
.not-found-button {
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 600;
  background-color: #104990;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
}
.delevery{
  font-size: 80px;
  font-weight: 700;
}
.servicetxt{
  color: #96c814;
}
.deleveryp{
  font-size: 28px;
}
.featur{
  font-size: 28px;
}
.develveryicon{
  color: #96c814;
}
.dbtn {
  background: #96c814 !important;
  border-radius: 35px !important;
  padding: 8px 25px !important;
  color: #fff !important;
  font-weight: 500 !important;
}
.dbtn:hover {
  border: #96c814 !important;
  border-radius: 35px !important;
  padding: 8px 25px !important;
  color: #fff !important;
  font-weight: 600 !important;
}
.dbanner{
background: #96c814 !important;
margin: 0px !important;
color: #000;
}
.sidebar{
  color: #000 !important;
  background: #c9e0f9;
}
@media only screen and (min-width: 349px) and (max-width: 768px) {
  * {
    margin: 0px !important;
    padding: 0px !important;
  }
  .feedtext{
  margin-right: 20px !important;
  margin-left: 20px !important;
  }
  .logo{
    margin-right: 20px !important;
  }
  .navbar-toggler{
    margin-left: 30px !important;
  }
  .mymenu {
    margin:5px  !important;
  }
  .carousel-image {
    height: 300px;
  }
  h3 {
    font-size: 28px !important;
  }
  h5 {
    font-size: 18px !important;
  }
  .service-card {
    margin: 0px 10px 10px 10px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .service-card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .bottle {
    position: relative;
    width: 100px;
    height: 150px; 
    display: flex;
    margin-top: -40px !important;
    margin-bottom: 10px !important;
    padding-top: 0px !important;
    padding-left: 20px;
    justify-content: left;
    align-items: left;
  }
  .bottle-icon {
    color:#104990 !important;
  }
  .water {
    position: absolute;
    width: 60%; 
    height: 50%; 
    bottom: 1%;
    background-color:#104990;
    box-shadow: inset 0 0 50px #64bedf;
    clip-path: polygon(0 0, 100% 0, 85% 100%, 15% 100%);
  }
  .water::before {
    content: "";
    width: 200%;
    height: 200%;
    background-color: #dae1fd;
    position: absolute;
    top: -150%;
    left: -50%;
    border-radius: 40%;
    animation: anim 12s linear infinite;
  }
  .water::after {
    content: "";
    width: 214%;
    height: 204%;
    background-color: #ececec80;
    position: absolute;
    top: -150%;
    left: -52%;
    border-radius: 40%;
    animation: anim 12s linear infinite;
    animation-delay: 0.5s;
  }
  .servicebtn {
    background: #104990 !important;
    border-radius: 35px !important;
    padding: 8px 20px !important;
    color: #fff !important;
    font-weight: 400 !important;
  }
  .servicebtn:hover {
    background: #96c814 !important;
    border-radius: 35px !important;
    padding: 8px 20px !important;
    color: #fff !important;
    font-weight: 400 !important;
  }
  .servicebtncontainer{
    margin-top: 20px !important;
  }
  .featurescol{
    margin: 0px 10px 10px 10px !important;
  }
  .vicons {
    background: #104990;
    text-align: center;
    padding: 10px !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    font-size: 18px !important;
    color: #fff;
  }
  .aboutimg {
    margin-top: -30px;
    margin-bottom: 20px !important;
    padding-left: 30px !important;
    text-align: center !important;
    height: 300px;
    width: 340px;
  }
  .sideheadings {
    font-size: 20px !important;
    margin-top: 20px;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
  .bestfeicon{
    color: white;
    font-size: 50px !important;
    margin-bottom: 10px !important;
  }
  .bestfeicon:hover{
    color: white !important;
    font-size: 50px !important;
    margin-bottom: 10px !important;
  }
  .bestferow{
    text-align: center !important;
  }
  .bestfetext{
    font-size: 16px !important;
    margin: 15px 0px !important;
  }
  .bestfe2head{
    font-size: 24px !important;
  }
  .bestfe2textbg{
    font-size: 24px !important;
    margin-top: 15px;
  }
  .bg-banner {
    background-position: center;
    background-size: cover; 
    max-height: 500px; 
  }
  .bestfe-banner {
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
  }
  .bestfecol{
    margin-bottom: 10px !important;
  }
  .whyrow{
   padding-left: 40px !important;
   padding-top: 40px !important;
  }
.fgmap{
  height: 200px !important;
  width: 350px !important;
}
  .whychosesecondcol {
    padding: 40px !important;
  }
  .gallery-col {
    display: flex;
    flex-direction: column;
  }
  .gallery-item {
    height: 300px;
    margin-bottom: 5px;
  }
  .gallery-img {
    flex: 1;
    height: 300px;
    object-fit: cover;
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0px !important;
  }
  .page-button {
    padding: 5px 15px !important;
    border: none;
    background-color: #104990;
    color: white;
    cursor: pointer;
    border-radius: 5px !important;
  }
  .page-button.active {
    background-color: #ffffff;
    color: #104990;
    font-weight: 600 !important;
    border: 2px solid #104990;
  }
  .page-button:hover:not(.active) {
    background-color: #104990;
  }
  .page-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  .icon-circle-why {
    padding: 20px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    font-size: 44px;
    color: #104990 !important;
  }
  .bestfe1headidgs {
    margin-top: 10px;
    padding-left: 10px;
    font-size: 20px;
    font-weight: 600;
  }
  .besft1para {
    text-align: left;
    padding-left: 10px;
    font-size: 14px;
  }
  .head1 {
    margin-top: 20px;
    font-size: 34px;
    font-weight: 800;
    color: #104990;
  }
  .head2 {
    font-size: 24px;
  }
  .aboutpara {
    padding: 0px 20px;
  }
  .fcontent {
    margin: 0px 30px;
  }
  .order-1 {
    order: 1;
  }
  .order-2 {
    order: 2;
  }
  .visionheading {
    font-size: 18px;
  }
  .visioneye {
    text-align: center;
    margin-top: 40px;
    font-size: 320px
  }
  .missionimg {
    padding: 20px;
    width: 300px; 
    text-align: center;
  }
  .missionhead1 {
    margin-top: 20px;
  }
  .missionheading {
    font-size: 18px;
  }
  .blog {
    padding: 0px 20px;
  }
  .head-icon-wrapper{
    margin-left:3px !important;

  }
  .abut-detail{
    padding-bottom: 10px !important;
  }
  .careerinfo {
    padding: 0px 20px !important;
  }
  .coreerinfo2 {
    padding: 0px 20px !important;
  }
  .qualificationdetail{
    margin-top: -30px !important;
  }
  .contact-form {
    margin-top: 20px;
  }
  .prdocutdetialshead {
    margin-top: 20px;
  }
  .shareicon {
    margin-top: -30px;
  }
  .share {
    margin-left: 60px;
  }
  .similarp {
    font-size: 20px;
    font-weight: 600;
    margin-top: 7px;
    padding-left: 10px;
  }
  .copyright-bg {
    background: #96c814;
    font-size: 14px;
    color: white;
    font-weight: 600;
  }
  .imgdelvery-fluid {
    max-width: 100%;
    height: auto;
  }
  .delevery{
    padding-left: 20px !important;
    font-size: 44px !important; 
    font-weight: 700 !important;
  }
  .servicetxt{
    color: #96c814;
  }
  .deleveryp{
    padding-left: 15px !important;
    padding-right: 15px !important;
    text-align: justify;
    font-size: 18px !important;
  }
  .delivery-icons{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .featur{
    padding-left: 20px !important;
    font-size: 24px !important;
  }
  .bestfecontactbtn{
    margin: 20px 0px  20px 10px !important;
  }
  .develveryicon{
    color: #96c814;
  }
  .dbtn {
    background: #96c814 !important;
    border-radius: 35px !important;
    padding: 5px 15px !important;
    color: #fff !important;
    font-weight: 400 !important;
    margin: 10px 0px 20px  0px !important;
  } 
  .dbtn:hover {
    border: #96c814 !important;
    border-radius: 35px !important;
    padding: 3px 15px !important;
    color: #fff !important;
    font-weight: 500 !important;
  }
  /* .feedbackitems{
    padding-left: 15px !important;
    padding-right: 15px !important;
  } */
 
  .banner3secondrow{
    padding-left: 10px !important;
  }
    .dbannertxt{
      padding: 5px 10px 5px 10px !important;
      font-size: 20px !important;
      text-align: justify;
    }
    .counts{
      margin-bottom: 10px !important;
    }
    .contacticon {
      background: #fff;
      text-align: center;
      padding: 10px !important; 
      width: 50px !important;
      height: 50px !important;
      border-radius: 50%;
      font-size: 24px !important;
      color: #104990 !important;
    }
}

.marquee-container {
  width: 100%;
  overflow: hidden;
}
.marquee {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 30s linear infinite;
}
@keyframes marquee {
  from { transform: translateX(100%); }
  to { transform: translateX(-100%); }
}
.marquee-container:hover .marquee {
  animation-play-state: paused;
}
.contact-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; 
  padding: 15px; 
}
.contact-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px; 
  height: 35px; 
  border-radius: 50%; 
  background-color: #fff; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  transition: transform 0.2s ease; 
}
.contact-icon-wrapper:hover {
  transform: scale(1.1);
}
.contact-icon {
  color: #104990; 
  font-size: 18px; 
}
.text-center {
  text-align: center; 
}
.contact-icon-wrapper:hover {
  transform: scale(1.1); 
}
.text-center {
  text-align: center; 
}
.bottle {
  position: relative;
  width: 100px;
  height: 150px; 
  display: flex;
  margin-top: -40px;
  padding-top: 0px;
  padding-left: 20px;
  justify-content: left;
  align-items: left;
}
.bottle-icon {
  color:#104990 !important;
}
.water {
  position: absolute;
  width: 60%; 
  height: 50%; 
  bottom: 1%;
  background-color:#104990;
  box-shadow: inset 0 0 50px #64bedf;
  clip-path: polygon(0 0, 100% 0, 85% 100%, 15% 100%);
}
.water::before {
  content: "";
  width: 200%;
  height: 200%;
  background-color: #dae1fd;
  position: absolute;
  top: -150%;
  left: -50%;
  border-radius: 40%;
  animation: anim 12s linear infinite;
}
.water::after {
  content: "";
  width: 214%;
  height: 204%;
  background-color: #ececec80;
  position: absolute;
  top: -150%;
  left: -52%;
  border-radius: 40%;
  animation: anim 12s linear infinite;
  animation-delay: 0.5s;
}
@keyframes anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fullscreen {
  pad: 0px;
  margin: 0px;
  width: 100vw  !important;
  height: 600px; 
}
.count-title {
  font-size: 40px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}
.count-text {
  font-size: 18px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}
.counter-icon{
  font-size: 40px;
}
.bannerbtn {
  background: #96c814 !important;
  border-radius: 35px !important;
  padding: 8px 20px !important;
  color: #fff !important;
  font-weight: 400 !important;
}
.quote-icon{
  color: #96c814;
  font-size: 45px;
  font-weight: 600;
}
.quote-icon-feedback{
  color: #104990;
  font-size: 45px;
  font-weight: 600;
}
/* src/App.css */
.sticky-contact-icon {
  position: fixed;
  bottom: 20px; 
  right: 20px; 
  z-index: 1000; 
}
.top-icon {
 color: #96c814;
 font-size: 30px;  
 transition: transform 0.3s ease;
}
.contact-icon-wrapper:hover .contact-icon {
  transform: scale(1.1); 
}